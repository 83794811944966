import { isSuccess } from "@devexperts/remote-data-ts";
import { Option, isSome, none, some } from "fp-ts/lib/Option";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AdminApplicationInfo,
  SelectedServicesInfo,
} from "../../../api/admin/model/adminApplicationInfo";
import { ApplicationApprovalRequest } from "../../../api/admin/model/applicationApprovalRequest";
import { BrReceipientInfo } from "../../../api/admin/model/brReceipientInfo";
import { CorporateUser } from "../../../api/admin/model/corporateUser";
import { AdminListOfCategories } from "../../../api/admin/model/onboarding/adminListOfCategories";
import { ListOfSelectedCategoriesApiReq } from "../../../api/admin/model/onboarding/listOfSelectedCategoryApiReq";
import { UpdateClaimsFlowApiReq } from "../../../api/admin/model/onboarding/updateClaimsFlowApiReq";
import { TAllAvailableCategories } from "../../../models/onboarding.models";
import PageLoader from "../../../page-loader";
import { CorporateService } from "../../../services/corporate.service";
import { OnboardingService } from "../../../services/onboarding.service";
import { unWrapOption } from "../../../utils/option.utils";
import PageTitle from "../../common/page-title";
import ApprovePopup from "../common/approve-popup";
import BrInfo from "../common/br-info";
import CompanyDetails from "../common/company-details";
import RejectPopup from "../common/reject-popup";
import ServiceSelected from "../common/service-selected";
import UpdatePopup from "../common/update-popup";
import UserDetailsInfo from "../common/user-details-info";
import CategorySelection from "./common/category-selection";
import PostApprovalClaimSelection from "./common/post-approval-claim-selection";

import PreApprovalClaimSelection from "./common/pre-approval-claims-selection";
import SetMaxLimit from "./common/set-max-limit";
import OnboardHeader from "./onboard-header";

import { fromNullable } from "fp-ts/lib/EitherT";

import "react-toastify/dist/ReactToastify.css";
import { CorporateServiceInfo } from "../../../model/corporateServiceInfo";
import CardVariant from "./common/card-variant";
import { console } from "fp-ts";
import { GetEmployeesDataEmployeesRes } from "../../../api/admin/model/maxLimitResponse";
import { ProfileSelect } from "../../../state/slice/profile.slice";
import { useAppSelector } from "../../../hooks/hooks";

export interface OptionDrop {
  label: string;
  value: string;
}

interface AppAction {
  type:
    | "appState"
    | "adminMaker"
    | "defaultApprover"
    | "corporateAdmin"
    | "businessUnit"
    | "corporateAdminList"
    | "defaultApproverList"
    | "adminMakerList"
    | "isNewClaimFlow"
    | "optedNewClaimFlow"
    | "claimsUserInfo"
    | "defaultBUCount";
  payload: AppState;
}

interface AppState {
  adminMaker: Option<CorporateUser>;
  defaultApprover: Option<CorporateUser>;
  corporateAdmin: Option<CorporateUser>;
  adminMakerList: Array<CorporateUser>;
  defaultApproverList: Array<CorporateUser>;
  corporateAdminList: Array<CorporateUser>;
  businessUnit: string[];
  isNewClaimFlow: boolean;
  optedNewClaimFlow: Option<boolean>;
  claimsUserInfo: Option<Array<CorporateUser>>;
  defaultBUCount: number;
}

function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case "appState": {
      // console.log("switch - setAppState");
      const appState = action.payload;
      return appState;
    }

    case "adminMaker": {
      // console.log("switch - adminMaker");
      const adminMaker = action.payload.adminMaker;
      return { ...state, adminMaker };
    }

    case "defaultApprover": {
      // console.log("switch - defaultApprover");
      const defaultApprover = action.payload.defaultApprover;
      return { ...state, defaultApprover };
    }

    case "corporateAdmin": {
      // console.log("switch - corporateAdmin");
      const corporateAdmin = action.payload.corporateAdmin;
      return { ...state, corporateAdmin };
    }
    case "businessUnit": {
      const businessUnit = action.payload.businessUnit;

      return { ...state, businessUnit };
    }

    case "corporateAdminList": {
      // console.log("switch - corporateAdminList", state);
      const corporateAdminList = action.payload.corporateAdminList;
      return {
        ...state,
        corporateAdminList,
      };
    }

    case "isNewClaimFlow": {
      // console.log("switch - corporateAdminList", state);
      const isNewClaimFlow = action.payload.isNewClaimFlow;
      return {
        ...state,
        isNewClaimFlow,
      };
    }

    case "defaultApproverList": {
      // console.log("switch - defaultApproverList", state);
      const defaultApproverList = action.payload.defaultApproverList;
      return {
        ...state,
        defaultApproverList,
      };
    }

    case "adminMakerList": {
      // console.log("switch - adminMakerList", state);
      const adminMakerList = action.payload.adminMakerList;
      return { ...state, adminMakerList };
    }

    case "optedNewClaimFlow": {
      const optedNewClaimFlow = action.payload.optedNewClaimFlow;

      return { ...state, optedNewClaimFlow };
    }

    case "claimsUserInfo": {
      const claimsUserInfo = action.payload.claimsUserInfo;

      return { ...state, claimsUserInfo };
    }

    case "defaultBUCount": {
      const defaultBUCount = action.payload.defaultBUCount;

      return { ...state, defaultBUCount };
    }

    default:
      console.warn(`appState - ${action.type} is unhandled`);
      return state;
  }
}

const initialState: AppState = {
  adminMaker: none,
  defaultApprover: none,
  corporateAdmin: none,
  corporateAdminList: [],
  adminMakerList: [],
  defaultApproverList: [],
  businessUnit: [],
  isNewClaimFlow: false,
  optedNewClaimFlow: none,
  claimsUserInfo: none,
  defaultBUCount: 0,
};

const OnboardDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showUpdateClaimFlow, setShowUpdateClaimFlow] = useState(false);
  const [showUpdateBusinessUnit, setShowUpdateBusinessUnit] = useState(false);
  const [listOfCat, setListOfCat] = useState<TAllAvailableCategories[]>([]);
  const [proceed, setProceed] = useState<boolean>(false);
  const [alias, setAlias] = useState("");
  const [editService, setEditService] = useState(false);
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [selectedServices, setSelectedServices] = useState<
    SelectedServicesInfo[]
  >([]);
  const approvedSuperAdmin = useAppSelector(
    ProfileSelect.selectApprovedSuperAdmin
  );
  const [selectOption, setSelectOption] = useState("RUPAY");
  const [annualLimit, setAnnualLimit] = useState(false);
  const setAppState = (appState: AppState) => {
    dispatch({ type: "appState", payload: appState });
  };
  const setAdminMaker = (adminMaker: Option<CorporateUser>) => {
    dispatch({ type: "adminMaker", payload: { ...state, adminMaker } });
  };

  const setCorporateAdmin = (corporateAdmin: Option<CorporateUser>) => {
    dispatch({
      type: "corporateAdmin",
      payload: { ...state, corporateAdmin },
    });
  };

  const [employeeList, setEmployeeList] = useState<
    GetEmployeesDataEmployeesRes[]
  >([]);
  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.getEmployeesWithCid(
        params.id ?? ""
      );
      if (isSuccess(result)) {
        setEmployeeList(result.value.data[0].employees);
      } else {
        // setAppState(initialState);
      }
    };
    if ((params.id?.length ?? 0) > 0) {
      run();
    }
  }, [params.id]);

  const setDefaultApprover = (defaultApprover: Option<CorporateUser>) => {
    dispatch({
      type: "defaultApprover",
      payload: { ...state, defaultApprover },
    });
  };

  const setBusinessUnit = (businessUnit: string[]) => {
    dispatch({
      type: "businessUnit",
      payload: { ...state, businessUnit },
    });
  };

  const setAdminMakerList = (adminMakerList: Array<CorporateUser>) => {
    // console.log("inside dis setAdminMakerList", adminMakerList);
    dispatch({
      type: "adminMakerList",
      payload: { ...state, adminMakerList },
    });
  };
  const setCorporateAdminList = (corporateAdminList: Array<CorporateUser>) => {
    // console.log("inside dis corporateAdminList", corporateAdminList);
    dispatch({
      type: "corporateAdminList",
      payload: { ...state, corporateAdminList },
    });
  };
  const setDefaultApproverList = (
    defaultApproverList: Array<CorporateUser>
  ) => {
    // console.log("inside dis defaultApproverList", defaultApproverList);
    dispatch({
      type: "defaultApproverList",
      payload: { ...state, defaultApproverList },
    });
  };
  const setNewClaimFlow = (isNewClaimFlow: boolean) => {
    // console.log("inside dis defaultApproverList", isNewClaimFlow);
    dispatch({
      type: "isNewClaimFlow",
      payload: { ...state, isNewClaimFlow },
    });
  };

  const setOptedNewClaimFlow = (optedNewClaimFlow: Option<boolean>) => {
    dispatch({
      type: "optedNewClaimFlow",
      payload: { ...state, optedNewClaimFlow },
    });
  };

  const setClaimsUserInfo = (claimsUserInfo: Option<CorporateUser[]>) => {
    // console.log("inside dis setClaimsUserInfo", claimsUserInfo);
    dispatch({
      type: "claimsUserInfo",
      payload: { ...state, claimsUserInfo },
    });
  };

  const [details, setDetails] = useState<AdminApplicationInfo>({
    corporateInfo: {
      cin: "",
      name: "",
      incDate: "",
      regAdd: "",
      rocCode: "",
      regNum: "",
      directors: [],
      cboCode: "",
      choCode: "",
    },
    applicantInfo: {
      name: "",
      mobile: "",
      email: "",
      emailVerified: true,
      mobileVerified: true,
    },
    brInfo: {
      status: "PENDING",
      document: "",
      sentOn: "",
      signedOn: "",
      receipients: [],
    },
    services: [],
    applicationStatus: "CIN_VERIFIED",
    accountStatus: "ACTIVE",
    appCode: "",
    provider: "RUPAY",
  });

  const setClaimsFlowDefaultValueBeforeApprove = (
    data: AdminApplicationInfo
  ) => {
    // console.log("setClaimsFlowDefaultValueBeforeApprove ===> ", data);
    let receipientsList: CorporateUser[] = (data.brInfo?.receipients || [])
      .filter((item): item is BrReceipientInfo => item !== undefined)
      .map(({ info }) => info)
      .filter((item): item is CorporateUser => item !== undefined);

    // setAppState(initialState);

    // setCorporateAdminList(receipientsList);

    // setDefaultApproverList(receipientsList);
    // setAdminMakerList(receipientsList);

    const convertedVal: Option<boolean> =
      data?.claimsNew !== undefined ? some(data?.claimsNew) : none;
    // console.log("setClaimsFlowDefaultValueBeforeApprove convertedVal ===>", convertedVal);
    const updatedAppState: AppState = {
      ...initialState,
      claimsUserInfo: state.claimsUserInfo,
      corporateAdminList: receipientsList,
      defaultApproverList: receipientsList,
      adminMakerList: receipientsList,
      optedNewClaimFlow: convertedVal,
    };
    setAppState(updatedAppState);
    // setOptedNewClaimFlow(convertedVal);
  };

  const setClaimsFlowDefaultValueWhileLive = (data: AdminApplicationInfo) => {
    // console.log("setClaimsFlowDefaultValue ===> ", data);
    let receipientsList: CorporateUser[] = data.adminData || [];

    // setAppState(initialState);

    // setCorporateAdminList(receipientsList);

    // setDefaultApproverList(receipientsList);
    // setAdminMakerList(receipientsList);

    const convertedVal: Option<boolean> =
      data?.claimsNew !== undefined ? some(data?.claimsNew) : none;
    // console.log("setClaimsFlowDefaultValue convertedVal ===>", convertedVal);
    const updatedAppState: AppState = {
      ...initialState,
      claimsUserInfo: state.claimsUserInfo,
      corporateAdminList: receipientsList,
      defaultApproverList: receipientsList,
      adminMakerList: receipientsList,
      optedNewClaimFlow: convertedVal,
      defaultBUCount: data.businessUnit?.length ?? 0,
    };
    setAppState(updatedAppState);
    // setOptedNewClaimFlow(convertedVal);
  };
  useEffect(() => {
    const run = async () => {
      if (params?.id) {
        const resultselectservices =
          await OnboardingService.getSelectedServices(params.id);

        if (
          isSuccess(resultselectservices) &&
          resultselectservices.value.data
        ) {
          setSelectedServices(resultselectservices.value.data);
        } else {
          setSelectedServices([]);
        }

        const result = await OnboardingService.detail(params?.id);
        if (isSuccess(result)) {
          if (result.value.successful) {
            if (result.value.data?.applicationStatus === "LIVE") {
              setDetails((prev) => (prev = result.value.data || prev));
            }
          }
        }
      }
    };

    run();
  }, [editService]);

  useEffect(() => {
    // const controller = new AbortController();
    const run = async () => {
      if (params?.id) {
        setIsLoading(true);

        const result = await OnboardingService.detail(params?.id);
        if (isSuccess(result)) {
          if (result.value.successful) {
            if (result.value.data?.applicationStatus === "REVIEW_PENDING") {
              setClaimsFlowDefaultValueBeforeApprove(result.value.data);

              const categoryResult =
                await OnboardingService.getAllAvailableCategories();
              if (isSuccess(categoryResult)) {
                setDetails((prev) => (prev = result.value.data || prev));

                setListOfCat(categoryResult.value);
              }
            } else if (result.value.data?.applicationStatus === "LIVE") {
              const corporateResult = await CorporateService.detail(params.id);

              if (isSuccess(corporateResult)) {
                if (corporateResult.value.data) {
                  setSelectOption(corporateResult.value.data.provider || "");

                  setClaimsFlowDefaultValueWhileLive(
                    corporateResult.value.data
                  );
                  // console.log("===> businessUnit corporate Detail api",corporateResult.value.data.businessUnit)
                  const listOfBusinessUnit =
                    corporateResult.value.data.businessUnit;
                  if (listOfBusinessUnit) {
                    setBusinessUnit(listOfBusinessUnit);
                  }
                  if (corporateResult.value.data.annualLimitSupport) {
                    setAnnualLimit(
                      corporateResult.value.data.annualLimitSupport
                    );
                  }
                  if (result.value?.data?.userInfo) {
                    setClaimsUserInfo(some(result.value?.data?.userInfo));
                  }
                } else {
                  setAppState(initialState);
                }
              } else {
                setAppState(initialState);
              }
              //selected services

              const categoryResult =
                await OnboardingService.getAllAvailableCategories();
              if (isSuccess(categoryResult)) {
                const corporateCatResult =
                  await OnboardingService.getAllCorporateCategories(params.id);
                if (isSuccess(corporateCatResult)) {
                  setDetails((prev) => (prev = result.value.data || prev));

                  let catList: TAllAvailableCategories[] = [];
                  categoryResult.value.map((allCat) => {
                    let present = false;
                    corporateCatResult.value.map((corporateCat) => {
                      // console.log("ar- ", allCat);
                      // console.log("cor- ", corporateCat);
                      if (
                        unWrapOption(allCat.fbaCategoryId, () => "") ===
                        unWrapOption(corporateCat.fbaCategoryId, () => "")
                      ) {
                        // console.log("I rised the flag");
                        present = true;
                        return;
                      }
                    });
                    if (!present) {
                      // console.log("<before-inloop-catList>", catList);
                      catList = [...catList, allCat];
                      // console.log("<after-inloop-catList>", catList);
                    }
                  });
                  // console.log("<before-outloop-catList>", catList);
                  catList = [...corporateCatResult.value, ...catList];
                  // console.log("<before-outloop-catList>", catList);
                  setListOfCat(catList);
                }
              }
            } else {
              setDetails((prev) => (prev = result.value.data || prev));
            }
          }
        }
        setIsLoading(false);
      }
    };
    run();
    // return controller.abort();
  }, [params.id]);

  if (isLoading) {
    return <PageLoader />;
  }

  const handleRejectPopup = (show: boolean) => {
    setShowReject(show);
  };

  const handleApprovePopup = (show: boolean) => {
    setShowApprove(show);
  };

  const handleReject = async (remark: string) => {
    const req: ApplicationApprovalRequest = {
      approved: false,
      remarks: remark,
    };

    if (params.id) {
      const result = await OnboardingService.approveOrReject(req, params.id);
      if (isSuccess(result)) {
        if (result.value.successful) {
          setDetails((prev) => (prev = result.value.data || prev));
        }
        setShowReject(false);
      }
    }
  };

  // const handleCategorySelection = (index: number, updateType: string) => {
  //   if (updateType == "Delete") {
  //     const list = [...listOfCat];
  //     if (!list[index].isEditable) {
  //       list[index].isSelected = !list[index].isSelected;
  //       // console.log("<<Testing-category list>>", list);
  //       setListOfCat(list);
  //     }
  //   } else {
  //     const list = [...listOfCat];
  //     if (list[index].isEditable) {
  //       list[index].isSelected = !list[index].isSelected;
  //       // console.log("<<Testing-category list>>", list);
  //       setListOfCat(list);
  //     }
  //   }
  // };

  const handleAddCategorySelection = (index: number) => {
    const list = [...listOfCat];
    if (list[index].isEligibleToAdd) {
      list[index].isSelected = !list[index].isSelected;

      setListOfCat(list);
    }
  };

  const handleDeleteCategorySelection = (index: number) => {
    const list = [...listOfCat];
    if (!list[index].isEligibleToAdd) {
      list[index].isSelected = !list[index].isSelected;

      setListOfCat(list);
    }
  };

  // const handleApprove = async () => {
  //   const req: ApplicationApprovalRequest = {
  //     approved: true,
  //     remarks: "",
  //   };

  //   if (alias.length > 0) {
  //     req["corpAliasName"] = alias;
  //   }

  //   // console.log("ONBOARDING-approvedReqBody-", req);
  //   if (params.id) {
  //     const result = await OnboardingService.approveOrReject(req, params.id);
  //     if (isSuccess(result)) {
  //       if (result.value.successful) {
  //         setDetails((prev) => (prev = result.value.data || prev));
  //         const categories: ListOfSelectedCategoriesApiReq = {
  //           cid: params.id,
  //           data: selectCategory(listOfCat),
  //         };
  //         // console.log("ONBOARDING-approvedCategories-", categories);
  //         const categoryResult = await OnboardingService.setCorporateCategory(
  //           categories
  //         );
  //         if (isSuccess(categoryResult)) {
  //           if (categoryResult.value.successful) {
  //             console.log("Category Updated");
  //           } else {
  //             console.log("Category Update Failed");
  //           }
  //         }
  //         if (result.value.data?.applicationStatus === "LIVE") {
  //           const categoryResult =
  //             await OnboardingService.getAllAvailableCategories();
  //           if (isSuccess(categoryResult)) {
  //             const corporateCatResult =
  //               await OnboardingService.getAllCorporateCategories(params.id);
  //             if (isSuccess(corporateCatResult)) {
  //               let catList: TAllAvailableCategories[] = [];
  //               categoryResult.value.map((allCat) => {
  //                 let present = false;
  //                 corporateCatResult.value.map((corporateCat) => {
  //                   // console.log("ar- ", allCat);
  //                   // console.log("cor- ", corporateCat);
  //                   if (
  //                     unWrapOption(allCat.fbaCategoryId, () => "") ===
  //                     unWrapOption(corporateCat.fbaCategoryId, () => "")
  //                   ) {
  //                     // console.log("I rised the flag");
  //                     present = true;
  //                     return;
  //                   }
  //                 });
  //                 if (!present) {
  //                   // console.log("<before-inloop-catList>", catList);
  //                   catList = [...catList, allCat];
  //                   // console.log("<after-inloop-catList>", catList);
  //                 }
  //               });
  //               // console.log("<before-outloop-catList>", catList);
  //               catList = [...corporateCatResult.value, ...catList];
  //               // console.log("<before-outloop-catList>", catList);
  //               setListOfCat(catList);
  //             }
  //           }
  //         }
  //       }
  //       setShowApprove(false);
  //     }
  //   }
  // };

  const handleApprove = async () => {
    if (state.businessUnit.length < 1) {
      console.warn("No business Unit Opted, So update is cancelled");
      return;
    }

    const req: ApplicationApprovalRequest = {
      approved: true,
      remarks: "",
      businessUnit: state.businessUnit,
      cardProvider: selectOption,
    };

    if (alias.length > 0) {
      req["corpAliasName"] = alias;
    }

    if (
      state.isNewClaimFlow &&
      isSome(state.corporateAdmin) &&
      isSome(state.adminMaker) &&
      isSome(state.defaultApprover)
    ) {
      // req["businessUnit"] = state.businessUnit;
      req["userInfo"] = [
        state.defaultApprover.value,
        state.adminMaker.value,
        state.corporateAdmin.value,
      ];
      req["claimsNew"] = true;
    }

    // console.log("ONBOARDING-approvedReqBody-", req);

    if (params.id) {
      const categories: ListOfSelectedCategoriesApiReq = {
        cid: params.id,
        data: selectCategory(listOfCat),
      };
      // console.log("ONBOARDING-approvedCategories-", categories);
      const categoryResult = await OnboardingService.setCorporateCategory(
        categories
      );

      if (isSuccess(categoryResult)) {
        if (categoryResult.value.successful) {
          console.log("Category Updated");

          const result = await OnboardingService.approveOrReject(
            req,
            params.id
          );
          if (isSuccess(result)) {
            if (result.value.successful) {
              setDetails((prev) => (prev = result.value.data || prev));

              setShowApprove(false);
              if (result.value.data?.applicationStatus === "LIVE") {
                const corporateResult = await CorporateService.detail(
                  params.id
                );
                if (isSuccess(corporateResult)) {
                  if (corporateResult.value.data) {
                    setClaimsFlowDefaultValueWhileLive(
                      corporateResult.value.data
                    );
                    // console.log("===> businessUnit corporate Detail api",corporateResult.value.data.businessUnit)
                    const listOfBusinessUnit =
                      corporateResult.value.data.businessUnit;
                    if (listOfBusinessUnit) {
                      setBusinessUnit(listOfBusinessUnit);
                    }

                    if (corporateResult.value.data.annualLimitSupport) {
                      setAnnualLimit(
                        corporateResult.value.data.annualLimitSupport
                      );
                    }

                    if (result.value?.data?.userInfo) {
                      setClaimsUserInfo(some(result.value?.data?.userInfo));
                    }

                    // const optedNewClaimFlow: Option<boolean> =
                    //   result.value?.data?.claimsNew !== undefined
                    //     ? some(result.value?.data?.claimsNew)
                    //     : none;

                    // const appState: AppState = {
                    //   ...initialState,
                    //   optedNewClaimFlow,
                    // };
                  } else {
                    setAppState(initialState);
                  }
                } else {
                  setAppState(initialState);
                }

                const AllcategoryResult =
                  await OnboardingService.getAllAvailableCategories();
                if (isSuccess(AllcategoryResult)) {
                  const corporateCatResult =
                    await OnboardingService.getAllCorporateCategories(
                      params.id
                    );
                  if (isSuccess(corporateCatResult)) {
                    let catList: TAllAvailableCategories[] = [];
                    AllcategoryResult.value.map((allCat) => {
                      let present = false;
                      corporateCatResult.value.map((corporateCat) => {
                        // console.log("ar- ", allCat);
                        // console.log("cor- ", corporateCat);
                        if (
                          unWrapOption(allCat.fbaCategoryId, () => "") ===
                          unWrapOption(corporateCat.fbaCategoryId, () => "")
                        ) {
                          // console.log("I rised the flag");
                          present = true;
                          return;
                        }
                      });
                      if (!present) {
                        // console.log("<before-inloop-catList>", catList);
                        catList = [...catList, allCat];
                        // console.log("<after-inloop-catList>", catList);
                      }
                    });
                    // console.log("<before-outloop-catList>", catList);
                    catList = [...corporateCatResult.value, ...catList];
                    // console.log("<before-outloop-catList>", catList);
                    setListOfCat(catList);
                  }
                }
              }
            }
          }
        } else {
          console.log("Category Update Failed");
        }
      } else {
        console.log("Category Update Failed");
      }
    }
  };

  const selectCategory = (
    catList: TAllAvailableCategories[]
  ): AdminListOfCategories[] => {
    let val: AdminListOfCategories[] = [];
    catList.map((d) => {
      if (d.isSelected) {
        if (
          isSome(d.fbaCategoryId) &&
          isSome(d.name) &&
          isSome(d.rule) &&
          isSome(d.newRegime)
        ) {
          const data: AdminListOfCategories = {
            fbaCategoryId: d.fbaCategoryId.value,
            name: d.name.value,
            rule: d.rule.value,
            newRegime: d.newRegime.value,
          };
          val = [...val, data];
        } else {
        }
      }
    });
    return val;
  };

  const unselectAllCategory = (categories: TAllAvailableCategories[]) => {
    let val: TAllAvailableCategories[] = [];
    categories.map((cat) => {
      cat.isSelected = false;
      val = [...val, cat];
    });
    setListOfCat(val);
  };

  const updateAddCorporateCategory = async () => {
    if (params.id) {
      const userSelectedCat: AdminListOfCategories[] =
        selectCategory(listOfCat);
      if (userSelectedCat.length < 1) {
        return;
      }
      const categories: ListOfSelectedCategoriesApiReq = {
        cid: params.id,
        data: userSelectedCat,
      };
      setIsLoading(true);
      const categoryResult = await OnboardingService.updateAddCorporateCategory(
        params.id,
        categories
      );
      if (isSuccess(categoryResult)) {
        if (categoryResult.value.successful) {
          console.log("Category Updated");
          const categoryResult =
            await OnboardingService.getAllAvailableCategories();
          if (isSuccess(categoryResult)) {
            const corporateCatResult =
              await OnboardingService.getAllCorporateCategories(params.id);
            if (isSuccess(corporateCatResult)) {
              setProceed(false);
              let catList: TAllAvailableCategories[] = [];
              categoryResult.value.map((allCat) => {
                let present = false;
                corporateCatResult.value.map((corporateCat) => {
                  // console.log("ar- ", allCat);
                  // console.log("cor- ", corporateCat);
                  if (
                    unWrapOption(allCat.fbaCategoryId, () => "") ===
                    unWrapOption(corporateCat.fbaCategoryId, () => "")
                  ) {
                    // console.log("I rised the flag");
                    present = true;
                    return;
                  }
                });
                if (!present) {
                  // console.log("<before-inloop-catList>", catList);
                  catList = [...catList, allCat];
                  // console.log("<after-inloop-catList>", catList);
                }
              });
              // console.log("<before-outloop-catList>", catList);
              catList = [...corporateCatResult.value, ...catList];
              // console.log("<before-outloop-catList>", catList);
              setListOfCat(catList);
            }
          }
        } else {
          console.log("Category Update Failed");
        }
      }
      setIsLoading(false);
    }
  };

  // const handleUpdate = () => {
  //   // if (isSome(state.optedNewClaimFlow)) {
  //   //   if (state.optedNewClaimFlow.value) {
  //   //     updateBusinessUnit();
  //   //   } else {
  //   //       updateNewClaimFlow();
  //   //   }
  //   // }
  //   if (
  //     isSome(state.optedNewClaimFlow) &&
  //     !state.optedNewClaimFlow.value &&
  //     state.isNewClaimFlow
  //   ) {
  //     updateNewClaimFlow();
  //     return;
  //   }
  //   updateBusinessUnit();
  // };

  const toastHandler = (msg: string) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const updateBusinessUnit = async () => {
    if (state.businessUnit.length < 1) {
      console.warn("no business unit selected, so update is cancelled");
      return;
    }

    const concatedValue = state.businessUnit.join(",");

    // console.log("updateBusinessUnit concatedVlue ===>", concatedValue);

    const result = await OnboardingService.updateBusinessUnit(
      params?.id ?? "",
      concatedValue
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        const corporateResult = await CorporateService.detail(params?.id ?? "");

        if (isSuccess(corporateResult)) {
          if (corporateResult.value.data) {
            setClaimsFlowDefaultValueWhileLive(corporateResult.value.data);
            // console.log("===> businessUnit corporate Detail api",corporateResult.value.data.businessUnit)
            const listOfBusinessUnit = corporateResult.value.data.businessUnit;
            if (listOfBusinessUnit) {
              setBusinessUnit(listOfBusinessUnit);
              toast.success("Business Unit updated successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            if (corporateResult.value.data.annualLimitSupport) {
              setAnnualLimit(corporateResult.value.data.annualLimitSupport);
            }
          } else {
            setAppState(initialState);
          }
        } else {
          setAppState(initialState);
        }
        // toastHandler("Update Successsfull");
      } else {
        toastHandler("can't update Business unit, Please try again");
      }
    } else {
      toastHandler("server error, can't update Business unit");
    }
    setShowUpdateBusinessUnit(false);
  };

  const updateNewClaimFlow = async () => {
    let userInfo: CorporateUser[] = [];
    if (
      isSome(state.corporateAdmin) &&
      isSome(state.adminMaker) &&
      isSome(state.defaultApprover)
    ) {
      userInfo = [
        state.defaultApprover.value,
        state.adminMaker.value,
        state.corporateAdmin.value,
      ];
      // console.log("updateNewClaimFlow inside if userInfo", userInfo);
    } else {
      toastHandler("server error, can't update new claim flow");

      return;
    }

    const claimFlow: UpdateClaimsFlowApiReq = {
      userInfo,
      claimsNew: state.isNewClaimFlow,
    };
    const result = await OnboardingService.updateClaimsFlow(
      params?.id ?? "",
      claimFlow
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        const corporateResult = await CorporateService.detail(params?.id ?? "");

        if (isSuccess(corporateResult)) {
          if (corporateResult.value.data) {
            setClaimsFlowDefaultValueWhileLive(corporateResult.value.data);
            // console.log("===> businessUnit corporate Detail api",corporateResult.value.data.businessUnit)
            const listOfBusinessUnit = corporateResult.value.data.businessUnit;
            if (listOfBusinessUnit) {
              setBusinessUnit(listOfBusinessUnit);
            }
            if (corporateResult.value.data.annualLimitSupport) {
              setAnnualLimit(corporateResult.value.data.annualLimitSupport);
            }
            const result = await OnboardingService.detail(params?.id ?? "");
            if (isSuccess(result)) {
              if (result.value.successful) {
                setSelectOption(corporateResult.value.data?.provider || "");
                if (result.value?.data?.userInfo) {
                  // console.log("===> userInfo", userInfo);
                  setClaimsUserInfo(some(result.value?.data?.userInfo));
                }
              }
            }
          } else {
            setAppState(initialState);
          }
        } else {
          setAppState(initialState);
        }
        // toastHandler("Update Successsfull");
      } else {
        toastHandler("can't update new claim flow, Please try again");
      }
    } else {
      toastHandler("server error, can't update new claim flow");
    }

    setShowUpdateClaimFlow(false);
  };

  const updateDeleteCorporateCategory = async () => {
    if (params.id) {
      const userSelectedCat: AdminListOfCategories[] =
        selectCategory(listOfCat);
      if (userSelectedCat.length < 1) {
        return;
      }
      const categories: ListOfSelectedCategoriesApiReq = {
        cid: params.id,
        data: userSelectedCat,
      };
      setIsLoading(true);
      const categoryResult =
        await OnboardingService.updateDeleteCorporateCategory(
          params.id,
          categories
        );
      if (isSuccess(categoryResult)) {
        if (categoryResult.value.successful) {
          console.log("Category Updated");
          const categoryResult =
            await OnboardingService.getAllAvailableCategories();
          if (isSuccess(categoryResult)) {
            const corporateCatResult =
              await OnboardingService.getAllCorporateCategories(params.id);
            if (isSuccess(corporateCatResult)) {
              let catList: TAllAvailableCategories[] = [];
              categoryResult.value.map((allCat) => {
                let present = false;
                corporateCatResult.value.map((corporateCat) => {
                  // console.log("ar- ", allCat);
                  // console.log("cor- ", corporateCat);
                  if (
                    unWrapOption(allCat.fbaCategoryId, () => "") ===
                    unWrapOption(corporateCat.fbaCategoryId, () => "")
                  ) {
                    // console.log("I rised the flag");
                    present = true;
                    return;
                  }
                });
                if (!present) {
                  // console.log("<before-inloop-catList>", catList);
                  catList = [...catList, allCat];
                  // console.log("<after-inloop-catList>", catList);
                }
              });
              // console.log("<before-outloop-catList>", catList);
              catList = [...corporateCatResult.value, ...catList];
              // console.log("<before-outloop-catList>", catList);
              setListOfCat(catList);
            }
          }
        } else {
          console.log("Category Update Failed");
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle title={`Application details | ${details.appCode}`} />
      <ToastContainer />
      <OnboardHeader
        detail={details.appCode}
        status={details.applicationStatus}
        setShowRejectPopup={handleRejectPopup}
        setShowApprovePopup={handleApprovePopup}
        listOfCat={listOfCat}
        businessUnit={state.businessUnit}
        adminMaker={state.adminMaker}
        defaultApprover={state.defaultApprover}
        isNewClaimFlow={state.isNewClaimFlow}
        corporateAdmin={state.corporateAdmin}
        alias={alias}
        selectedCategory={(
          catList: TAllAvailableCategories[]
        ): AdminListOfCategories[] => selectCategory(catList)}
      />
      {details.corporateInfo && (
        <CompanyDetails
          agreement={details.brInfo?.document}
          corporateInfo={details.corporateInfo}
        />
      )}
      {details.applicantInfo && (
        <UserDetailsInfo applicantInfo={details.applicantInfo} />
      )}
      {details.brInfo && details.brInfo.receipients && (
        <BrInfo brInfo={details.brInfo} />
      )}
      {details.services && (
        <ServiceSelected
          id={params?.id ?? ""}
          // services={details.services}
          selectedServices={selectedServices}
          setSelectedServices={(data: SelectedServicesInfo[]) =>
            setSelectedServices(data)
          }
          applicationStatus={details.applicationStatus || ""}
          editService={editService}
          setEditService={(val: boolean) => setEditService(val)}
          fundName={details.corporateInfo?.npsFund || ""}
          cboCode={details.corporateInfo?.cboCode || ""}
          choCode={details.corporateInfo?.choCode || ""}
        />
      )}
      {(details.applicationStatus === "REVIEW_PENDING" ||
        details.applicationStatus === "LIVE") && (
        <CategorySelection
          proceed={proceed}
          setProceed={(val: boolean) => setProceed(val)}
          applicationStatus={details.applicationStatus || ""}
          catList={listOfCat}
          handleAddCategorySelection={(index: number) =>
            handleAddCategorySelection(index)
          }
          handleDeleteCategorySelection={(index: number) =>
            handleDeleteCategorySelection(index)
          }
          setAlias={(val: string) => setAlias(val)}
          alias={alias}
          unSelectAllCategory={(val: TAllAvailableCategories[]) => {
            unselectAllCategory(val);
          }}
          updateAddCorporateCategory={() => updateAddCorporateCategory()}
          updateDeleteCorporateCategory={() => updateDeleteCorporateCategory()}
          cid={params.id ?? ""}
          employeeList={employeeList}
        />
      )}

      {details.applicationStatus === "LIVE" && approvedSuperAdmin && (
        <SetMaxLimit
          listOfCat={listOfCat}
          cid={params.id ?? ""}
          employeeList={employeeList}
          annualLimit={annualLimit}
        />
      )}
      {details.applicationStatus === "REVIEW_PENDING" && (
        <PreApprovalClaimSelection
          setDefaultApprover={(defaultApprover: Option<CorporateUser>) =>
            setDefaultApprover(defaultApprover)
          }
          setAdminMaker={(adminMaker: Option<CorporateUser>) =>
            setAdminMaker(adminMaker)
          }
          setCorporateAdmin={(corporateAdmin: Option<CorporateUser>) =>
            setCorporateAdmin(corporateAdmin)
          }
          setBusinessUnit={(data: string[]) => setBusinessUnit(data)}
          setDefaultApproverList={(defaultApproverList: Array<CorporateUser>) =>
            setDefaultApproverList(defaultApproverList)
          }
          setAdminMakerList={(adminMakerList: Array<CorporateUser>) =>
            setAdminMakerList(adminMakerList)
          }
          setCorporateAdminList={(corporateAdminList: Array<CorporateUser>) =>
            setCorporateAdminList(corporateAdminList)
          }
          setNewClaimFlow={(isNewClaimFlow: boolean) =>
            setNewClaimFlow(isNewClaimFlow)
          }
          defaultApproverList={state.defaultApproverList}
          adminMakerList={state.adminMakerList}
          corporateAdminList={state.corporateAdminList}
          businessUnit={state.businessUnit}
          defaultApprover={state.defaultApprover}
          adminMaker={state.adminMaker}
          corporateAdmin={state.corporateAdmin}
          isNewClaimFlow={state.isNewClaimFlow}
        />
      )}
      {details.applicationStatus === "LIVE" && (
        <PostApprovalClaimSelection
          setDefaultApprover={(defaultApprover: Option<CorporateUser>) =>
            setDefaultApprover(defaultApprover)
          }
          setAdminMaker={(adminMaker: Option<CorporateUser>) =>
            setAdminMaker(adminMaker)
          }
          setCorporateAdmin={(corporateAdmin: Option<CorporateUser>) =>
            setCorporateAdmin(corporateAdmin)
          }
          setBusinessUnit={(data: string[]) => setBusinessUnit(data)}
          setDefaultApproverList={(defaultApproverList: Array<CorporateUser>) =>
            setDefaultApproverList(defaultApproverList)
          }
          setAdminMakerList={(adminMakerList: Array<CorporateUser>) =>
            setAdminMakerList(adminMakerList)
          }
          setCorporateAdminList={(corporateAdminList: Array<CorporateUser>) =>
            setCorporateAdminList(corporateAdminList)
          }
          setNewClaimFlow={(isNewClaimFlow: boolean) =>
            setNewClaimFlow(isNewClaimFlow)
          }
          setShowUpdateClaimFlowPopup={() => setShowUpdateClaimFlow(true)}
          setShowUpdateBusinessUnitPopup={() => setShowUpdateBusinessUnit(true)}
          defaultApproverList={state.defaultApproverList}
          adminMakerList={state.adminMakerList}
          corporateAdminList={state.corporateAdminList}
          businessUnit={state.businessUnit}
          defaultApprover={state.defaultApprover}
          adminMaker={state.adminMaker}
          corporateAdmin={state.corporateAdmin}
          isNewClaimFlow={state.isNewClaimFlow}
          optedNewClaimFlow={state.optedNewClaimFlow}
          claimsUserInfo={state.claimsUserInfo}
          defaultBUCount={state.defaultBUCount}
        />
      )}
      {(details.applicationStatus === "REVIEW_PENDING" ||
        details.applicationStatus === "LIVE") && (
        <CardVariant
          status={details.applicationStatus}
          setSelectOption={(val: string) => setSelectOption(val)}
          selectOption={selectOption}
        />
      )}
      {/* <AlertPopup /> */}

      <RejectPopup
        show={showReject}
        onHide={() => handleRejectPopup(false)}
        onReject={handleReject}
      />

      <ApprovePopup
        show={showApprove}
        onHide={() => setShowApprove(false)}
        onApprove={handleApprove}
      />
      <UpdateBusinessUnitPopUp
        show={showUpdateBusinessUnit}
        onHide={() => setShowUpdateBusinessUnit(false)}
        onUpdate={updateBusinessUnit}
      />
      <UpdateClaimFlowPopUp
        show={showUpdateClaimFlow}
        onHide={() => setShowUpdateClaimFlow(false)}
        onUpdate={updateNewClaimFlow}
      />
    </>
  );
};

const UpdateClaimFlowPopUp = ({
  show,
  onHide,
  onUpdate,
}: {
  show: boolean;
  onHide: () => void;
  onUpdate: () => void;
}) => {
  return <UpdatePopup show={show} onHide={onHide} onUpdate={onUpdate} />;
};

const UpdateBusinessUnitPopUp = ({
  show,
  onHide,
  onUpdate,
}: {
  show: boolean;
  onHide: () => void;
  onUpdate: () => void;
}) => {
  return <UpdatePopup show={show} onHide={onHide} onUpdate={onUpdate} />;
};

export default OnboardDetails;
