import { remoteData } from "@devexperts/remote-data-ts";
import { tryCatch } from "fp-ts/lib/Either";
import { fromNullable, none } from "fp-ts/lib/Option";
import { AuthResponse } from "../model/auth/authResponse";

import { RefreshTokenApiService } from "../api/auth/refreshTokenApi.service";
import { SignupResponse } from "../model/auth/signupResponse";
import { TAuthResponse } from "../types/auth-response";
import { TLoginResponse } from "../types/login-response.type";
import { TSignupResponse } from "../types/signup-response.type";
import { ConvertToRoutePath } from "../utils/route.utils";

import { NcaishInternalAuthApiService } from "../api/admin/api/ncaishInternalAuthApi.service";
import { LoginResponse } from "../api/admin/model/loginResponse";
import { OAuthLoginApiService } from "../api/auth/oauthLoginApi.service";

import { UserAccountAuthApiService } from "../api/oauth/api/userAccountAuthApi.service";
import { ChangePasswordRequest } from "../api/oauth/model/changePasswordRequest";
import { ResetPasswordApiRequest } from "../api/oauth/model/resetPasswordApiRequest";
import { ResetPasswordApiResponse } from "../api/oauth/model/resetPasswordApiResponse";
import { SendForgotPasswordOtpRequest } from "../api/oauth/model/sendForgotPasswordOtpRequest";
import { SendForgotPasswordOtpResponse } from "../api/oauth/model/sendForgotPasswordOtpResponse";
import { SwitchAccountApiRequest } from "../api/oauth/model/switchAccountApiRequest";
import { SwitchAccountTokenResponse } from "../api/oauth/model/switchAccountTokenResponse";
import { ValidateSwitchAccountTokenRequest } from "../api/oauth/model/validateSwitchAccountTokenRequest";
import { VerifyOtpApiRequest } from "../api/oauth/model/verifyOtpApiRequest";
import { VerifyOtpApiResponse } from "../api/oauth/model/verifyOtpApiResponse";
import { OAuthLoginResponse } from "../model/auth/oauthLogin.model";
import { TOAuthLoginResponse } from "../types/oauth-login-response.type";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AuthService {
  export const loginEither = (mobile: string) =>
    tryCatch(
      () => login(mobile),
      () => `Error logging in`
    );

  export const loginUsingPassword = async (req: {
    username: string;
    password: string;
  }) => {
    const result = await OAuthLoginApiService.loginUsingPassword({
      username: req.username,
      password: req.password,
    });
    return remoteData.map(result, parseOAuthResponse);
  };

  export const refreshOAuthToken = async (refreshToken: string) => {
    const result = await OAuthLoginApiService.refreshOAuthToken(refreshToken);
    return remoteData.map(result, parseOAuthResponse);
  };

  const parseOAuthResponse = (
    response: OAuthLoginResponse
  ): TOAuthLoginResponse => {
    return {
      access_token: fromNullable(response.access_token),
      token_type: fromNullable(response.token_type),
      refresh_token: fromNullable(response.refresh_token),
      expires_in: fromNullable(response.expires_in),
      scope: fromNullable(response.scope),
      id: fromNullable(response.id),
      cid: fromNullable(response.cid),
      email: fromNullable(response.email),
      full_name: fromNullable(response.full_name),
      roles: fromNullable(response.roles),
      jti: fromNullable(response.jti),
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
    };
  };

  export const login = async (mobile: string) => {
    const result = await NcaishInternalAuthApiService.login({
      mobile,
      type: "NCAISH_ADMIN",
    });
    return remoteData.map(result, parseLogin);
  };

  const parseLogin = (response: LoginResponse): TLoginResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      loginId: fromNullable(response.loginId),
      nextScreen: fromNullable(ConvertToRoutePath(response.redirection)),
    };
  };

  export const verifyLogin = async (loginId: string, otp: string) => {
    const result = await NcaishInternalAuthApiService.verifyLogin({
      loginId,
      otp,
    });

    return remoteData.map(result, parseAuthResponse);
  };

  // export const signup = async (req: {
  //   applicationId: string;
  //   name: string;
  //   email: string;
  //   mobile: string;
  // }) => {
  //   const result = await UserSignupApiService.signup({
  //     applicationId: req.applicationId,
  //     name: req.name,
  //     email: req.email,
  //     mobile: req.mobile,
  //   });
  //   return remoteData.map(result, parseSignup);
  // };

  // export const verifySignup = async (
  //   verificationId: string,
  //   signupId: string,
  //   motp: string,
  //   eotp: string
  // ) => {
  //   const result = await UserSignupApiService.verifySignup({
  //     verificationId,
  //     signupId,
  //     eotp,
  //     motp,
  //   });
  //   return remoteData.map(result, parseAuthResponse);
  // };

  export const refreshToken = async (refreshToken: string) => {
    const result = await RefreshTokenApiService.refreshToken(refreshToken);
    return remoteData.map(result, parseAuthResponse);
  };

  const parseSignup = (response: SignupResponse): TSignupResponse => {
    return {
      nextAction: fromNullable(ConvertToRoutePath(response.nextAction)),
      message: fromNullable(response.message),
      successful: fromNullable(response.successful),
      verificationId: fromNullable(response.verificationId),
      signupId: fromNullable(response.signupId),
    };
  };

  const parseAuthResponse = (loginRes: AuthResponse): TAuthResponse => ({
    successful: fromNullable(loginRes.successful),
    message: fromNullable(loginRes.message),
    status: fromNullable(loginRes.status),
    accessToken: fromNullable(loginRes.accessToken),
    refreshToken: fromNullable(loginRes.refreshToken),
    nextAction: fromNullable(ConvertToRoutePath(loginRes.nextAction)),
    data: loginRes.data
      ? {
          name: fromNullable(loginRes.data.name),
          email: fromNullable(loginRes.data.email),
          mobile: fromNullable(loginRes.data.mobile),
          gender: fromNullable(loginRes.data.gender),
          profileImg: fromNullable(loginRes.data.profileImg),
          corporateInfo: loginRes.data.corporateInfo
            ? {
                cid: fromNullable(loginRes.data.corporateInfo.cid),
                name: fromNullable(loginRes.data.corporateInfo.name),
                status: fromNullable(loginRes.data.corporateInfo.status),
                cin: fromNullable(loginRes.data.corporateInfo.cid),
              }
            : { cid: none, name: none, status: none, cin: none },
        }
      : {
          name: none,
          email: none,
          mobile: none,
          gender: none,
          profileImg: none,
          corporateInfo: {
            cid: none,
            name: none,
            status: none,
            cin: none,
          },
        },
    scope: fromNullable(loginRes.scope),
    expiry: fromNullable(loginRes.expiry),
  });
  export const sendOtpOnForgotPassword = async (
    body: SendForgotPasswordOtpRequest
  ) => {
    const result = await UserAccountAuthApiService.sendOtp(body);
    return remoteData.map(
      result,
      (response: SendForgotPasswordOtpResponse) => response
    );
  };

  export const verifyForgotPasswordOtp = async (body: VerifyOtpApiRequest) => {
    const result = await UserAccountAuthApiService.verifyForgotPasswordOtp(
      body
    );
    return remoteData.map(result, (response: VerifyOtpApiResponse) => response);
  };

  export const changePassword = async (body: ChangePasswordRequest) => {
    const result = await UserAccountAuthApiService.changePassword(body);

    return remoteData.map(result, (response: VerifyOtpApiResponse) => response);
  };

  export const resetPassword = async (body: ResetPasswordApiRequest) => {
    const result = await UserAccountAuthApiService.resetPassword(body);

    return remoteData.map(
      result,
      (response: ResetPasswordApiResponse) => response
    );
  };

  export const revoke = async () => {
    const result = await UserAccountAuthApiService.revoke();
    return remoteData.map(result, (response: string) => response);
  };

  export const switchAccountTokenUrl = async (
    body: SwitchAccountApiRequest
  ) => {
    const result = await UserAccountAuthApiService.switchAccountToken(body);
    return remoteData.map(
      result,
      (response: SwitchAccountTokenResponse) => response
    );
  };

  export const loginWithToken = async (
    body: ValidateSwitchAccountTokenRequest
  ) => {
    const result = await UserAccountAuthApiService.switchAccount(body);
    return remoteData.map(result, parseOAuthResponse);
  };
}
