import { isSome } from "fp-ts/lib/Option";
import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
import PageLoader from "../../../page-loader";
import { MembershipReduxStore } from "../../../state/slice/membership.slice";
import { useGetMembershipBatchesCount } from "./hooks/membership.hooks";
// import CountCard from "./utilies/count-card";
import CountCard from "./utilies/count-card";

const MembershipStatusCount: React.FC = () => {
  const filter = useAppSelector(MembershipReduxStore.selectFilter);
  const {
    data: counts,
    isLoading: isLoadingCounts,
    isError: isErrorCounts,
  } = useGetMembershipBatchesCount();

  if (isLoadingCounts) {
    // console.log("membership-status-count-1 :: ", counts);
    return <PageLoader />;
  }
  if (counts && isSome(counts)) {
    return (
      <div className="card-counts">
        {counts.value.length > 0 ? (
          counts.value.map((item, index) => (
            <CountCard
              key={index}
              count={item.count || 0}
              status={item.label || ""}
              label={item.status || ""}
              isActive={Boolean(
                item.label
                  ? item.label.toLowerCase() === filter.status.toLowerCase()
                  : false
              )}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <div></div>;
};

export default MembershipStatusCount;
