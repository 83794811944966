import { isSuccess } from "@devexperts/remote-data-ts";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { OAuthLoginApiService } from "../../api/auth/oauthLoginApi.service";
import { resetProfile, selectProfile } from "../../state/slice/profile.slice";
import { titleCase } from "../../utils/common.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { RoutePaths } from "../../utils/route.utils";
import { setCurrentScreen } from "../app/app.slice";
import ReuseIcon from "../common/reuse-icons/reuse-icons";
import CustomSearchBar from "../custom-search/custom-search";
import LogoutPopup from "../pages/dashboard/common/logout-popup";
import "./sub-header.scss";

const DashboardSubHeader = () => {
  const data = useSelector(selectProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const handleLogout = async () => {
    const result = await OAuthLoginApiService.oAuthLogout();
    if (isSuccess(result)) {
      MyStorage.AuthToken.ClearData();
      MyStorage.AuthUser.ClearData();
      MyStorage.LoginId.ClearData();
      dispatch(resetProfile());
      navigate("/login");
    } else {
      console.log("logout api call failed");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end my-2" id="sub-header">
        {/* <CustomSearchBar /> */}
        <div className="d-flex justify-content-center align-items-center">
          {/* <ReuseIcon className="mx-3">
            <svg
              id="Group_2842"
              className="comman-icon"
              data-name="Group 2842"
              xmlns="http://www.w3.org/2000/svg"
              width="120.462"
              height="142.833"
              viewBox="0 0 120.462 142.833"
            >
              <path
                id="Path_6222"
                data-name="Path 6222"
                d="M-316.9,437.669c0,4.536-.229,8.958.053,13.346.462,7.19,1.643,14.319,4.9,20.811a49.846,49.846,0,0,0,6.444,9.364c2.37,2.834,3.3,5.956,1.937,9.257-1.271,3.081-3.94,4.73-7.7,4.731q-49.114.006-98.226.023c-3.644,0-6.406-1.328-7.921-4.721-1.452-3.25-.667-6.671,2.116-9.368,5.708-5.528,8.165-12.635,9.778-20.129,1.69-7.85,1.936-15.818,1.771-23.82-.215-10.368,1.024-20.5,5.588-29.969a41.839,41.839,0,0,1,24.572-22.172,5.458,5.458,0,0,0,2.064-1.868c1.657-2.077,2.957-4.528,4.9-6.271a9.278,9.278,0,0,1,12.5.079,10.8,10.8,0,0,1,3.133,3.607c1.361,3.252,3.8,4.757,6.957,5.9,2.66.964,5.072,2.617,7.819,4.084-9.659,8.712-13.226,18.944-8.375,30.853S-330.078,438.193-316.9,437.669Zm-58.67-44.709a26.037,26.037,0,0,0-2.738,1.1,35.525,35.525,0,0,0-14.433,15,31.847,31.847,0,0,0-3.458,18.9,3.677,3.677,0,0,0,1.342,2.228,1.969,1.969,0,0,0,1.916-.4,4.356,4.356,0,0,0,.611-2.606,29.481,29.481,0,0,1,6.349-21.045,30.864,30.864,0,0,1,10.658-9.012,3.079,3.079,0,0,0,1.387-2.286C-373.927,394.223-374.974,393.6-375.57,392.959Z"
                transform="translate(418.127 -374.501)"
              />
              <path
                id="Path_6223"
                data-name="Path 6223"
                d="M-314.9,408.405a22.854,22.854,0,0,1-23.462,22.755,22.823,22.823,0,0,1-22.3-23.082,22.779,22.779,0,0,1,23.031-22.552A22.838,22.838,0,0,1-314.9,408.405Z"
                transform="translate(435.36 -371.195)"
                fill="#f10000"
              />
              <path
                id="Path_6224"
                data-name="Path 6224"
                d="M-369.467,470.318c4.627,0,9.255-.023,13.883.008,3.815.026,5.452,2.488,4.059,6.025A19.439,19.439,0,0,1-369.537,488.6a19.546,19.546,0,0,1-17.916-12.1c-1.474-3.667.161-6.141,4.1-6.156,4.626-.02,9.255-.005,13.883-.005Z"
                transform="translate(427.165 -345.77)"
              />
            </svg>
          </ReuseIcon> */}

          <ReuseIcon className="dropdown">
            <Link
              className="border-0 rounded-circle wrap-profile"
              to=""
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 140.667 140.667"
              >
                <path
                  id="user"
                  d="M76.333,76.333A35.167,35.167,0,1,1,111.5,41.167,35.167,35.167,0,0,1,76.333,76.333Zm0,26.375c28.01,0,53.143,5.017,70.333,26.932v17.027H6V129.64C23.191,107.72,48.323,102.708,76.333,102.708Z"
                  transform="translate(-6 -6)"
                  fill="#5f6b76"
                />
              </svg>
            </Link>

            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuLink"
              style={{ width: 300 }}
            >
              <li>
                <div className="row">
                  {/* <div className="col-md-3 col-lg-3"> */}
                  {/* <div className="pt-2 px-3 mr-1">
                      <img
                        src="https://media.istockphoto.com/photos/middle-aged-man-with-pleasant-face-expression-picture-id1005817658?b=1&k=20&m=1005817658&s=170667a&w=0&h=tpil3FJm2k6CHWiVczRjC-dBR8kP1ZckPMVETYX-89g="
                        width="70"
                        height="60"
                        alt="user profile"
                        className="rounded-3"
                      />
                    </div> */}
                  {/* </div> */}
                  <div className="col-md-9 col-lg-9">
                    <Link
                      to={RoutePaths.DashboardMyProfile}
                      className="dropdown-item"
                    >
                      <div>{data ? data.name : "Username"}</div>
                      <div className="badge bg-primary opacity-25 ">
                        {data?.role &&
                          titleCase(
                            data?.role
                              .replace(/role_/i, "")
                              .split("_")
                              .join(" ")
                          )}
                      </div>
                      {/* <p className="fw-light p-0 m-0" style={{ fontSize: 11 }}>
                        current company name
                      </p> */}
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  to={RoutePaths.DashboardMyProfile}
                  className="dropdown-item"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                  </svg>{" "}
                  My Account
                </Link>
              </li>
              <li>
                <Link
                  to={RoutePaths.DashboardChangePassword}
                  className="dropdown-item"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                  </svg>{" "}
                  Change Password
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  className="dropdown-item"
                  onClick={() => setShowPopup(true)}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M21 3.01H3c-1.1 0-2 .9-2 2V9h2V4.99h18v14.03H3V15H1v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14a2 2 0 00-2-2zM11 16l4-4-4-4v3H1v2h10v3z"></path>
                  </svg>{" "}
                  Sign Out
                </Link>
              </li>
            </ul>
          </ReuseIcon>
        </div>
      </div>
      <LogoutPopup
        show={showPopup}
        onHide={() => setShowPopup(false)}
        onLogout={() => handleLogout()}
      />
    </>
  );
};
export default DashboardSubHeader;
