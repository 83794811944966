import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { AdminAllEmployeeListApiResponse } from "../../admin/model/all-employees/adminAllEmployeeListApiResponse";
import { AdminAllEmployeeStatuscountApiResponse } from "../../admin/model/all-employees/adminAllEmployeesStatusCountApiResponse";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";
import { BaseResponse } from "../model/baseResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

type filterVal = [TQueryParam] | [];

export namespace NcaishAllEmployeeApiService {
  export const getAllEmployeeList = async (
    status: string,
    pn: number,
    rc: number,
    from: string,
    to: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminAllEmployeeListApiResponse>> => {
    const path = `/secure/detax/subscriptions/get/users`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getAllEmployeeListSearch = async (
    keyword?: string,
    status?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminAllEmployeeListApiResponse>> => {
    const path = `/secure/detax/subscriptions/cards/search-all`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getAllEmployeesStatusCounts = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminAllEmployeeStatuscountApiResponse>> => {
    const path = `/secure/detax/subscriptions/cards/all/count`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    // console.log("<testing:: queryParams>", queryParams);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const resetUserPassword = async (
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/admin/reset/password/${uid}`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    // console.log("<testing:: queryParams>", queryParams);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
