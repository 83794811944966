import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { TrialCountInfo } from "../../../api/admin/model/trialCountInfo";
import { TrialService } from "../../../services/trial.service";
import { addAllTrialCount } from "../../../utils/company.utils";
import WhiteCard from "../../white-card/white-card";
import "../card-count.scss";
import CardLoader from "../common/card-loader";
//TrialService
const TrialStatusCount: React.FC<{
  setCurTbl: (tbl: TrialCountInfo) => void;
  curTbl: TrialCountInfo | null;
}> = ({ setCurTbl, curTbl }) => {
  const [counts, setCounts] = useState<Array<TrialCountInfo> | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const trialStatusCount = await TrialService.trialCounts();
      // console.log("trialStatusCount",trialStatusCount)
      setIsLoading(false);
      if (isSuccess(trialStatusCount)) {
        if (trialStatusCount.value.successful) {
          if ((trialStatusCount.value.data || []).length > 0) {
            const counts = addAllTrialCount(trialStatusCount.value.data || []);
            setCounts(counts);
            setCurTbl(counts[0]);
          }
        }
      } else if (isFailure(trialStatusCount)) {
        // alert(trialStatusCount?.error?.outcome)
        console.log("trialCounts: ", trialStatusCount?.error?.outcome);
      }
    };
    run();
  }, []);

  return (
    <>
      {isLoading ? (
        <CardLoader />
      ) : (
        <div className="card-counts mt-4">
          {counts.map((item, key) => (
            <div
              key={key}
              onClick={() => setCurTbl(item)}
              className="card-count"
            >
              <WhiteCard
                className={classNames("p-2 w-75 status-dot-card", {
                  color6: item.state == "PENDING",
                  color7: item.state == "LIVE",
                  color8: item.state == "EXPIRED",
                  active: curTbl?.state == item.state,
                })}
              >
                <div className="status-dot"></div>

                <h2 className="text-black text-center mb-0 fw-bold">
                  {item.count}
                </h2>
                <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                  {item.label}
                </p>
              </WhiteCard>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default TrialStatusCount;
