import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import app from "../components/app/app.slice";
import company from "../components/company/company.slice";

import dashboard from "./slice/dashboard.slice";
import detax from "./slice/detax.slice";
import fx from "./slice/fx.slice";
import profile from "./slice/profile.slice";
import token from "./slice/token.slice";
import signup from "./slice/user.slice";
import membership from "./slice/membership.slice";
import allEmployees from "./slice/allEmployee.slice";

import storage from "redux-persist/lib/storage";
import { createMigrate, persistReducer } from "redux-persist";

const middlewareConfiguration = { serializableCheck: false };

const reducers = combineReducers({
  app,
  company,
  fx,
  signup,
  token,
  dashboard,
  detax,
  profile,
  membership,
  allEmployees,
});

const migrations = {
  0: (state: any) => {
    return {
      ...state,
      app: {
        ...state.app,
        commonError: { showError: false, errorText: "" },
      },
    };
  },
};

const persistConfig = {
  key: "root-super-admin",
  version: 0,
  storage,
  blacklist: ["detax", "signup"],
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: "Ncaish-Super-Admin",
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(middlewareConfiguration),
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function dispatchOnCall(action: Action) {
  return () => store.dispatch(action);
}
