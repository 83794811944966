import { Modal } from "react-bootstrap";
// import "././reject-popup.scss";
const SuccessPopup: React.FC<{
  message?: string;
  show?: boolean;
  onClose: (show: boolean) => void;
}> = ({ message = "Successful!", show = false, onClose }) => {
  return (
    <>
      <Modal show={show} onClose={() => onClose(false)} centered>
        <Modal.Header className="d-flex flex-column justify-content-center mt-3">
          <div className="text-center rounded-circle bg-success  text-light  fs-2 ncaish-w-50">
            &#10004;
          </div>
          <Modal.Title className="text-success mt-3 mb-3">
            {message}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer
          className="ncaish-bg-grey d-flex justify-content-center pointer"
          onClick={() => onClose(false)}
        >
          <p className="light fw-bold">OK</p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuccessPopup;
