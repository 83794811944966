import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import OtpField from "react-otp-field";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { AuthService } from "../../services/auth.service";
import { fromPublic } from "../../utils/common.utils";
import { RoutePaths } from "../../utils/route.utils";
import CardButton from "../common/button/card-button";
import FormikComponent from "../common/formik/formik-component/formik-component";

import SpinnerComponent from "../common/loader/spinner";
import SuccessPopup from "./dashboard/common/succes-popup";
import { ForgotPassWithMobile } from "./form/forget-password-mobile";

const Image3 = fromPublic("/images/bg1-new.svg");

interface IValues {
  mobile: string;
}

interface IData {
  mobile: string;
  otpId: string;
  otpVal: string;
}

const formInitialSchema: IValues = {
  mobile: "",
};
const validationSchema = yup.object().shape({
  mobile: yup.string().required("Please enter your username"),
});

const Scrren1Page: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  setScreen: (data: number) => void;
}> = ({ data, setData, setScreen }) => {
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values: IValues) => {
    setError("");
    setIsLoading(true);
    const result = await AuthService.sendOtpOnForgotPassword({
      username: values.mobile,
    });

    setIsLoading(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setScreen(1);
        let newData = data;
        newData.mobile = values.mobile;
        newData.otpId = result.value.otpId || "";
        setData(newData);
      } else {
        setError(result.value.message || "");
      }
    } else {
      setError("OTP couldn't sent");
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Ncaish</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="row align-items-center vh-100">
              <div className="col-lg-8 col-md-6 ">
                <MediumScreen>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="Mobile"
                    />
                  </div>
                  <div className="textDetail mt-5">
                    <Heading
                      className="mb-3 mt-85"
                      text="Forgot Password"
                      variant="small"
                    />

                    <p className="fw-bolder text-secondary">
                      Being a Fintech we understand the importance of Multi
                      Factor authentication prescribed by Reserve Bank of India
                      !
                    </p>
                  </div>
                </MediumScreen>
              </div>
              <div className="mt-4 col-lg-4  col-md-6">
                <MediumScreen>
                  <div className="container p-0 m-0">
                    <div className="card  p-3 bg-grey shadow rounded-15 border-0">
                      {error && (
                        <div
                          className="alert alert-danger text-center fw-bold"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      <Heading
                        className="text-center mt-120"
                        text="Forgot Password"
                        variant="large"
                      />
                      <p className="d-none d-sm-none d-md-block text-center mb-0 fw-bold text-black mt-4 mb-4">
                        Kindly enter your username
                      </p>
                      <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block text-center">
                        <ForgotPassWithMobile />
                        <div className="text-center ml-auto mr-auto mb-5 mt-5">
                          <Button type="submit">
                            {isLoading ? <SpinnerComponent /> : <>Send OTP</>}
                          </Button>
                        </div>
                      </div>

                      <p className="fw-bolder text-secondary text-center mb-5 mt-1">
                        Back to
                        <Link className="ncash_nav-link" to={RoutePaths.Login}>
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </MediumScreen>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="card  p-3 bg-grey shadow border-0">
                  {error && (
                    <div
                      className="alert alert-danger text-center fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="BG"
                    />
                  </div>

                  <h1 className="fw-bold text-center mb-4 heading fs-5 mt-3">
                    Forgot Password
                  </h1>
                  <p className="d-md-none d-lg-none d-xl-none d-xxl-none text-center mb-0 fw-bold text-black fs-small">
                    Kindly enter your Mobile number
                  </p>

                  <div className="card  inputCard p-3 mt-4 mb-4 d-md-none d-lg-none d-xl-none d-xxl-none">
                    <ForgotPassWithMobile />
                  </div>
                  <div className="text-center ml-auto mr-auto mb-5">
                    <Button type="submit">
                      {isLoading ? <SpinnerComponent /> : <>Send OTP</>}
                    </Button>
                  </div>

                  <p className="fw-bolder text-secondary text-center  mt-1 ">
                    Back to
                    <Link className="ncash_nav-link" to={RoutePaths.Login}>
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

const Scrren2Page: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  setScreen: (data: number) => void;
}> = ({ data, setData, setScreen }) => {
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState(false);

  const [otpCounter, setOtpCounter] = useState(25);
  console.log("2.data", data);
  useEffect(() => {
    setData({ ...data, otpVal: "" });
  }, []);

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpCounter]);
  //https://vaptapi.ncaish.ai/oauth/set/password
  const handleSubmit = async () => {
    if (data.otpVal.length === 6) {
      let payload = {
        otpId: data.otpId,
        otp: data.otpVal,
      };

      setIsLoading(true);
      const result = await AuthService.verifyForgotPasswordOtp(payload);
      setIsLoading(false);
      if (isSuccess(result)) {
        if (result.value.successful) {
          if (result.value.otpId) {
            setData({ ...data, otpId: result.value.otpId });
            setError("");
            setScreen(3);
          } else {
            //setError("Something went wrong");
          }
        } else {
          setError(result.value.message || "");
        }
      } else {
        //setError("Something went wrong");
      }
    } else {
      setError("Invalid OTP");
    }
  };

  const resendOTP = async () => {
    setError("");
    setIsLoading(true);
    const result = await AuthService.sendOtpOnForgotPassword({
      username: data.mobile,
    });
    //
    setIsLoading(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setScreen(1);
        setData({ ...data, otpId: result.value.otpId || "" });
      } else {
        setError(result.value.message || "");
      }
    } else {
      setError("OTP couldn't sent");
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Ncaish</title>
      </Helmet>
      <div className="container p-0">
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image3}
                    alt="Mobile"
                  />
                </div>
                <div className="textDetail mt-5">
                  <Heading
                    className="mb-3 mt-85"
                    text="Forgot Password"
                    variant="small"
                  />

                  <p className="fw-bolder text-secondary">
                    Being a Fintech we understand the importance of Multi Factor
                    authentication prescribed by Reserve Bank of India !
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4  col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="card shadow px-3 py-2 bg-grey shadow rounded-15 border-0">
                    <Heading
                      className="text-center mt-5"
                      text="OTP Verification"
                      variant="large"
                    />
                    {error && (
                      <div
                        className="alert alert-danger text-center fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <span className="text-center fw-bold text-black mt-3 mb-3 text-capitalize">
                      We have sent the code verification to your <br />
                      mobile number
                    </span>
                    <span className="text-center fw-bold text-black mb-1 mt-3  fs-5">
                      +91-{data.mobile} &nbsp;
                      <CardButton icon={"edit"} onClick={() => setScreen(0)} />
                    </span>
                    <div
                      id="otp"
                      className="inputs d-flex flex-row justify-content-center mt-3 mb-3"
                    >
                      <OtpField
                        classNames="d-flex "
                        value={data.otpVal}
                        onChange={(o: string) =>
                          setData({ ...data, otpVal: o })
                        }
                        numInputs={6}
                        onChangeRegex={/^([0-9]{0,})$/}
                        autoFocus
                        inputProps={{
                          className:
                            "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                          disabled: false,
                        }}
                      />
                    </div>
                    <p className="fw-bolder text-secondary text-center mb-0 mt-1">
                      Didn't receive Verification Code?
                    </p>
                    <Link
                      to=""
                      className={
                        "fw-bolder text-darkblue text-center nav-link " +
                        (otpCounter <= 0 ? " " : "disabled")
                      }
                      onClick={() => resendOTP()}
                    >
                      Resend Code{" "}
                      <span className="text-danger mt-2">00:{otpCounter}</span>
                    </Link>

                    {/* <ResendButton /> */}

                    <div className="text-center ml-auto mr-auto mt-5 mb-20">
                      <Button
                        type="button"
                        className={
                          data.otpVal.length !== 6
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!data.otpVal}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <SpinnerComponent /> : <>Continue</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="card  p-3 bg-grey shadow border-0">
                {error && (
                  <div
                    className="alert alert-danger text-center fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image3}
                    alt="BG"
                  />
                </div>

                <h1 className="fw-bold text-center mb-4 heading fs-5 mt-3">
                  OTP Verification
                </h1>
                <p className="d-md-none d-lg-none d-xl-none d-xxl-none text-center mb-0 fw-bold text-black fs-small">
                  We have sent the code verification to your mobile number
                </p>

                <div className="card  inputCard p-3 mt-4 mb-4 d-md-none d-lg-none d-xl-none d-xxl-none">
                  {/* <ForgotPassWithMobile /> */}
                  <span className="text-center fw-bold text-black mb-1 mt-3  fs-5">
                    +91-{data.mobile} &nbsp;
                    <CardButton icon={"edit"} onClick={() => setScreen(0)} />
                  </span>
                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center mt-3 mb-3"
                  >
                    <OtpField
                      classNames="d-flex "
                      value={data.otpVal}
                      onChange={(o: string) => setData({ ...data, otpVal: o })}
                      numInputs={6}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      inputProps={{
                        className:
                          "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                        disabled: false,
                      }}
                    />
                  </div>
                  <p className="fw-bolder text-secondary text-center mb-0 mt-1">
                    Didn't receive Verification Code?
                  </p>
                  <Link
                    to=""
                    className={
                      "fw-bolder text-darkblue text-center nav-link " +
                      (otpCounter <= 0 ? " " : "disabled")
                    }
                    onClick={() => resendOTP()}
                  >
                    Resend Code{" "}
                    <span className="text-danger mt-2">00:{otpCounter}</span>
                  </Link>

                  {/* <ResendButton /> */}

                  <div className="text-center ml-auto mr-auto mt-5 mb-20">
                    <Button
                      type="button"
                      className={
                        data.otpVal.length !== 6
                          ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                          : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                      }
                      disabled={!data.otpVal}
                      onClick={handleSubmit}
                    >
                      {isLoading ? <SpinnerComponent /> : <>Continue</>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </>
  );
};

const Scrren3Page: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  setScreen: (data: number) => void;
  setShowSuccess: (data: boolean) => void;
}> = ({ data, setData, setScreen, setShowSuccess }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async () => {
    let payload = {
      otpId: data.otpId,
      password: newPassword,
    };
    //
    setError("");
    if (newPassword != "") {
      if (newPassword === confirmPassword) {
        if (newPassword.length >= 6 && newPassword.length <= 30) {
          setIsLoading(true);
          const result = await AuthService.resetPassword(payload);
          setIsLoading(false);
          if (isSuccess(result)) {
            if (result.value.successful) {
              if (result.value.logout) {
                localStorage.clear();
                navigate("/login");
              }
              setShowSuccess(true);
            } else {
              setError(result.value.message || "");
            }
          } else {
            //setError("Something went wrong");
          }
        } else {
          setError("Password must be between 6 and 30 characters");
        }
      } else {
        setError("Password and Confirm Password does not match");
      }
    } else {
      setError("Password is required");
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - Ncaish</title>
      </Helmet>
      <div className="container p-0">
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image3}
                    alt="Mobile"
                  />
                </div>
                <div className="textDetail mt-5">
                  <Heading
                    className="mb-3 mt-85"
                    text="Reset Password"
                    variant="small"
                  />

                  <p className="fw-bolder text-secondary">
                    Being a Fintech we understand the importance of Multi Factor
                    authentication prescribed by Reserve Bank of India !
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4  col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="card shadow px-3 py-2 bg-grey shadow rounded-15 border-0">
                    <Heading
                      className="text-center mt-5"
                      text="Reset Password"
                      variant="large"
                    />
                    {error && (
                      <div
                        className="alert alert-danger text-center fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <div className="form-group mb-3 mt-5">
                      <label htmlFor="newPassword">New Password</label>
                      <input
                        id="newPassword"
                        className="form-control"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type="password"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        id="confirmPassword"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                      />
                    </div>

                    <div className="text-center ml-auto mr-auto mt-5 mb-20">
                      <Button
                        type="button"
                        className={
                          data.otpVal.length !== 6
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!data.otpVal}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <SpinnerComponent /> : <>Save Changes</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="card  p-3 bg-grey shadow border-0">
                <Heading
                  className="text-center mt-5"
                  text="Reset Password"
                  variant="large"
                />
                {error && (
                  <div
                    className="alert alert-danger text-center fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="form-group mb-3 mt-5">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    id="newPassword"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    id="confirmPassword"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                  />
                </div>

                <div className="text-center ml-auto mr-auto mt-5 mb-20">
                  <Button
                    type="button"
                    className={
                      data.otpVal.length !== 6
                        ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                        : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                    }
                    disabled={!data.otpVal}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <SpinnerComponent /> : <>Save Changes</>}
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </>
  );
};

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [screen, setScreen] = useState<number>(0);
  const [data, setData] = useState<IData>({
    mobile: "",
    otpId: "",
    otpVal: "",
  });

  return (
    <>
      <Helmet>
        <title>Forgot Password - Ncaish</title>
      </Helmet>
      {screen === 0 ? (
        <Scrren1Page setScreen={setScreen} data={data} setData={setData} />
      ) : screen === 1 ? (
        <Scrren2Page setScreen={setScreen} data={data} setData={setData} />
      ) : (
        <Scrren3Page
          setScreen={setScreen}
          data={data}
          setData={setData}
          setShowSuccess={setShowSuccess}
        />
      )}
      <SuccessPopup
        message={`Password has updated successfully`}
        show={showSuccess}
        onClose={(flag) => navigate(RoutePaths.Login)}
      />
    </>
  );
};

export default ForgotPasswordPage;
