import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable } from "fp-ts/lib/Option";
import { NcaishAdminApplicationApiService } from "../api/admin/api/ncaishAdminApplicationApi.service";
import { AdminApplicationListResponse } from "../api/admin/model/adminApplicationListResponse";
import { AdminCorpApplicationCountInfo } from "../api/admin/model/adminCorpApplicationCountInfo";
import { AdminKitmanagementBasicInfo } from "../api/admin/model/adminkitmanagementBasicInfo";
import { ApplicationCountApiResponse } from "../api/admin/model/applicationCountApiResponse";
import { NcaishCorporateApplicationApiService } from "../api/corporate/api/ncaishCorporateApplicationApi.service";
import { TKitmanagementItem } from "../types/admin/kitmanagement-item.type";
import { TOnBoardingApplicationCountInfo } from "../types/admin/onboarding-application-count.type";
// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace KitManagementService {
  // kit management
  export const KitCounts = async () => {
    const result = await NcaishAdminApplicationApiService.applicationCounts1();
    return remoteData.map(result, (response: ApplicationCountApiResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationCount) : [],
    }));
  };

  const parseApplicationCount = (
    app: AdminCorpApplicationCountInfo
  ): TOnBoardingApplicationCountInfo => {
    return {
      label: fromNullable(app.label),
      state: fromNullable(app.state),
      count: fromNullable(app.count),
    };
  };

  export const onboardingListByState1 = async (state: string) => {
    // const filter = state === "ALL" ? "" : state;
    const result = await NcaishCorporateApplicationApiService.fetchByState1Kit(
      state
    );
    return remoteData.map(result, (response: AdminApplicationListResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationInfokit) : [],
    }));
  };

  const parseApplicationInfokit = (
    app: AdminKitmanagementBasicInfo
  ): TKitmanagementItem => {
    return {
      id: app.id || "",
      kitNumber: app.kitNumber || "",
      type: app.type || "",
      status: app.status || "",
      refId: app.refId || "",
      refName: app.refName || "",
      //   state: fromNullable(app.state),
      createdAt: app.createdAt || "",
      createdBy: app.createdBy || "",
      usedAt: app.usedAt || "",
      blockedAt: app.blockedAt || "",
    };
  };

  // FOR TYPE LIST API(EMPLOYEE / CORPORATE)
  export const onboardingListByEmployee = async (state: string) => {
    const filter = state === "ALL" ? "" : state;
    const result = await NcaishCorporateApplicationApiService.fetchByTypeEmpKit(
      filter
    );
    return remoteData.map(result, (response: AdminApplicationListResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationInfokit1) : [],
    }));
  };

  const parseApplicationInfokit1 = (
    app: AdminKitmanagementBasicInfo
  ): TKitmanagementItem => {
    return {
      id: app.id || "",
      kitNumber: app.kitNumber || "",
      type: app.type || "",
      status: app.status || "",
      refId: app.refId || "",
      refName: app.refName || "",
      //   state: fromNullable(app.state),
      createdAt: app.createdAt || "",
      createdBy: app.createdBy || "",
      usedAt: app.usedAt || "",
      blockedAt: app.blockedAt || "",
    };
  };
  export const onboardingListByStatus = async (state: string) => {
    const filter = state === "ALL" ? "" : state;
    const result = await NcaishCorporateApplicationApiService.fetchByStatus(
      filter
    );
    return remoteData.map(result, (response: AdminApplicationListResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationInfokit2) : [],
    }));
  };

  const parseApplicationInfokit2 = (
    app: AdminKitmanagementBasicInfo
  ): TKitmanagementItem => {
    return {
      id: app.id || "",
      kitNumber: app.kitNumber || "",
      type: app.type || "",
      status: app.status || "",
      refId: app.refId || "",
      refName: app.refName || "",
      //   state: fromNullable(app.state),
      createdAt: app.createdAt || "",
      createdBy: app.createdBy || "",
      usedAt: app.usedAt || "",
      blockedAt: app.blockedAt || "",
    };
  };

  // FOR CREATE A NEW EMPLOYEE OR CORPORATE DETAILS API
  export const createByNewKit = async (
    state: string,
    obj: string[],
    provider: string
  ) => {
    // const filter = state === "ALL" ? "" : state;
    const result = await NcaishCorporateApplicationApiService.createEmp(
      state,
      obj,
      provider
    );
    return remoteData.map(result, (response: AdminApplicationListResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationInfokit2) : [],
    }));
  };
  // sld======================================
}
