import { TDashboardMenu } from "../types/dashboard-menu.type";
import { RoutePaths } from "../utils/route.utils";

export const dashboardMenus: TDashboardMenu[] = [
  {
    label: "Onboarding",
    iconImagePath: "/images/icons/menus/home-icon.svg",
    path: RoutePaths.NavigateToOnboarding,
  },
  {
    label: "Corporate",
    iconImagePath: "/images/icons/menus/analysis-icon.svg",
    path: RoutePaths.NavigateToCorporate,
  },
  {
    label: "Employees",
    iconImagePath: "/images/icons/menus/analysis-icon.svg",
    path: RoutePaths.NavigateToAllEmployees,
  },
  {
    label: "Trial",
    iconImagePath: "/images/icons/menus/fx-online.svg",
    path: RoutePaths.NavigateToTrial,
  },
  {
    label: "Batch",
    iconImagePath: "/images/icons/menus/transaction-icon.svg",
    path: RoutePaths.DashboardTransaction,
  },
  //  {
  //   label: "Employees",
  //   iconImagePath: "/images/icons/menus/employees-icon.svg",
  //   path: RoutePaths.Employees,
  // },
  {
    label: "Kit",
    iconImagePath: "/images/icons/menus/kitManagemet_icon.svg",
    path: RoutePaths.NavigateToKitManagement,
  },
  {
    label: "Membership",
    iconImagePath: "/images/icons/menus/membership-icon.svg",
    path: RoutePaths.Membership,
  },
];
