import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { dashboardMenus } from "../../config/dashboard-menu";
import { Dispatch } from "../../state/dispatch";
import { TDashboardMenu } from "../../types/dashboard-menu.type";
import { RoutePaths } from "../../utils/route.utils";
import "./sidebar.scss";

type TSidebarMenuProps = {
  menuItems?: TDashboardMenu[];
};

const menuArr = [];

const SidebarMenu: React.FC<TSidebarMenuProps> = ({
  menuItems = dashboardMenus,
}) => {
  const location = useLocation();

  const activeSidemenu = (path: string) => {
    // let flag = false;
    // let tmpPath = location.pathname.split("/", 2).join("/").toLowerCase();
    // let possiblePaths: string[] = [];
    // if (path == RoutePaths.Onboarding || tmpPath == RoutePaths.OnboardDetails)
    //   possiblePaths = [RoutePaths.Onboarding, RoutePaths.OnboardDetails];
    // else if (path == RoutePaths.Trial || tmpPath == RoutePaths.TrialDetails)
    //   possiblePaths = [RoutePaths.Trial, RoutePaths.TrialDetails];
    // else if (path == RoutePaths.Corporate || tmpPath == RoutePaths.CorporateDetails)
    //   possiblePaths = [RoutePaths.Corporate, RoutePaths.CorporateDetails];
    // else if (path == RoutePaths.Employees)
    //   possiblePaths = [RoutePaths.Employees];

    // possiblePaths = possiblePaths.map(i => i.toLowerCase())

    // if (possiblePaths.includes(tmpPath)) flag = true;
    return location.pathname.includes(path);
  };

  return (
    <ul className="manu-list">
      {menuItems.map((menu: TDashboardMenu, index: any) => {
        return (
          <li key={index}>
            <Link
              to={menu.path}
              className={classNames({
                active: activeSidemenu(menu.path),
              })}
              onClick={() => Dispatch.Detax.hideUploadTransaction()}
            >
              <img
                src={menu.iconImagePath}
                alt={menu.label}
                width="24"
                height="28"
                className="pb-2 w-h-size"
              />
              <p> {menu.label} </p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
export default SidebarMenu;
